

.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    background-color: #000 !important;
}

.css-8je8zh-MuiTouchRipple-root {
    /* background: #000 !important; */
    border: 2px solid #ab6f31 !important;
    color: #ab6f31 !important;
  }
  
  .css-17slstj-MuiSvgIcon-root {
    z-index: 9999999;
  }
  
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background-color: #000 !important;
  }
  
  .css-i4bv87-MuiSvgIcon-root {
    z-index: 99999999;
  }
  
  .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    background-color: #000 !important;
    color: #ab6f31;
  }
  
  .css-1w5lpmw-MuiSpeedDialIcon-root {
    background: none !important;
  }
  
  .css-13srr63-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
    background-color: #000 !important;
  }
  
  .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
    background-color: #000 !important;
  }
  
  @media (hover: none) {
    .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
        background-color: #000 !important;
    }
  }
  
  .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
    background-color: #000 !important;
  }
  
  .css-1j4tx5p-MuiSvgIcon-root {
    background-color: #000 !important;
  }
  
  .css-13srr63-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
    background-color: #000 !important;
  }
  
  .css-1e2cad8-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
    background-color: #000 !important;
  }
  
  .css-14lw37v-MuiButtonBase-root-MuiButton-root {
    background-color: #000 !important;
  }
  
  .css-bshv44-MuiButtonBase-root-MuiListItem-root {
    margin-bottom: 2px !important;
  }