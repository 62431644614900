/* navbar */

.Nav-logo {
    width: 110px;
    height: auto;
    filter: drop-shadow(5px 5px 5px #ab6f31);
    /* background-color: #ab6f31; 
    border-radius: 50%; */
  }
  
  .MuiAppBar-colorPrimary {
    color: #ab6f31;
    background-color: #000 !important;
  }
  
  .navbar {
    background-color: #000;
    color: #ab6f31;
    /* background: rgb(4,120,63);
background: linear-gradient(302deg, rgba(4,120,63,1) 9%, rgba(255,0,0,1) 99%); */
  
  }
  
  .each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .css-7e0s21-MuiPaper-root-MuiAppBar-root {
    /* border-bottom: 0.5px solid #ab6f31 !important; */
    box-shadow: 0px 0.5px 0px 0px #ab6f31 !important;

  }