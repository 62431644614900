

/* Base Styles */
.product-page {
    padding: 20px;
    text-align: center;
    width: 80%;
    margin: auto;
  }
  
  .product-page h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #ab6f31;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 10px;
  }
  
  .product-card {
    background-color: #000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    cursor: pointer;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    /* border-bottom: 1px solid #ab6f31; */
  }
  
  .product-name {
    font-size: 1.5em;
    margin: 15px 0;
    color: #ab6f31;
  }
  
  .product-price {
    font-size: 1.2em;
    color: #f0f0e3;
    margin-bottom: 20px;
  }
  
  /* Popup Overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-card {
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    text-align: center;
    position: relative;
  }
  
  .popup-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .popup-name {
    font-size: 2em;
    color: #ab6f31;
    margin-bottom: 10px;
  }
  
  .popup-price {
    font-size: 1.5em;
    color: #f0f0e3;
    margin-bottom: 20px;
  }
  
  .popup-details {
    font-size: 1.2em;
    color: #f0f0e3;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ab6f31;
    color: #000;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 1em;
  }
  
  .close-btn:hover {
    background-color: #ab6f31;
  }
  
  /* Media Queries */
  
  /* For large screens (Desktops and large laptops) */
  @media (min-width: 1024px) {
    
  
    .popup-image {
      height: 400px;
    }
  
    .popup-name {
      font-size: 2.0em;
    }
  
    .popup-price {
      font-size: 1.8em;
    }
  
    .popup-details {
      font-size: 1.0em;
    }

    .popup-image {
        height: 250px;
      }

    .popup-card {
        background-color: #000;
        padding: 20px;
        border-radius: 10px;
        max-width: 500px;
        width: 90%;
        text-align: center;
        position: relative;
        top: 60px;
    }

  }
  
  /* For medium screens (Tablets and small laptops) */
  @media (min-width: 768px) and (max-width: 1023px) {
  
    .popup-name {
      font-size: 1.2em;
    }
  
    .popup-price {
      font-size: 0.9em;
    }
  
    .popup-details {
      font-size: 0.8em;
    }

    .popup-image {
        height: 250px;
      }

    .popup-card {
        background-color: #000;
        padding: 20px;
        border-radius: 10px;
        max-width: 500px;
        width: 90%;
        text-align: center;
        position: relative;
        top: 60px;
    }

  }
  
  /* For small screens (Phones) */
  @media (max-width: 767px) {
    .product-page {
      width: 95%;
    }
  
    .product-page h1 {
      font-size: 2em;
    }
  
    .product-card {
      transition: transform 0.2s;
    }
  
    .product-image {
      height: 180px;
    }
  
    .product-name {
      font-size: 1.1em;
    }
  
    .product-price {
      font-size: 0.8em;
    }
  
    .popup-card {
      max-width: 90%;
      padding: 15px;
    }
  
    .popup-image {
      height: 250px;
    }
  
    .popup-name {
      font-size: 1.8em;
    }
  
    .popup-price {
      font-size: 1.5em;
    }
  
    .popup-details {
      font-size: 1.1em;
    }
  }
  

  @media (min-width: 320px) and (max-width: 480px) {
  
    .popup-image {
      height: 200px;
    }
  
    .popup-name {
      font-size: 1.2em;
    }
  
    .popup-price {
      font-size: 1.0em;
    }
  
    .popup-details {
      font-size: 0.8em;
    }

    .popup-card {
        background-color: #000;
        padding: 20px;
        border-radius: 10px;
        max-width: 500px;
        width: 90%;
        text-align: center;
        position: relative;
        top: 60px;
    }

  }
  