
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');


*{
  /* background: #000; */
  color: #ab6f31;
}

body {
  background-color: #000;
  background: rgb(199,199,199);
background: linear-gradient(306deg, rgba(199,199,199,1) 36%, rgba(255,255,255,1) 77%);
background-image: url('./images/swagbg1.png');
background-repeat: no-repeat;
background-position: center;
background-size: cover;
}

li::marker {
  content: '🏎'; 
  /* 👩🏻‍🎓˙✧˖°🎓 ༘⋆｡   ♾️ ∞︎︎ ∞ */
      font-size: 30px; 
      /* color: #ab6f31;  */
      
      /* https://emojidb.org/ */
}

/* li {
  list-style-type: none; 
  position: relative;
  padding-left: 35px; 
}

li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); 
  width: 22px; 
  height: 22px;
  background-image: url('./images/li-car.png'); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
} */



h1{
  font-family: 'Cinzel';
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
}

h2{
  font-family: 'Cinzel';
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
}

p{
  /* font-family: 'Times New Roman', Times, serif; */
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400 900;
  font-display: swap;
  
}


.home {
  text-align: center;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.home h1 {
  font-size: 2.5rem;
  color: #ab6f31;
  margin-bottom: 20px;
}

.home p {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 40px;
  line-height: 1.6;
  text-align: justify;
}

.home-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}

.section {
  /* background-color: #000; */
  color: #ab6f31;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: calc(50% - 40px);
  min-width: 280px;
}

.section h2 {
  font-size: 1.8rem;
  color: #ab6f31;
  margin-bottom: 15px;
}

.section p {
  font-size: 1rem;
  color: #000;
  text-align: justify;


}

@media (max-width: 768px) {
  .home-sections {
    flex-direction: column;
  }

  .section {
    width: 100%;
    margin: 10px 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .home-sections {
    flex-direction: column;
  }

  .home p {
    font-size: 0.8rem;
    text-align: justify;
  }

  .section {
    width: 100%;
    margin: 10px 0;
  }

  .home {
    text-align: center;
    padding: 20px;
    width: 100%;
    margin: auto;
  }

  .home h1 {
    font-size: 1.2rem;
    color: #ab6f31;
    margin-bottom: 20px;
  }
}

.about-us {
  text-align: center;
  padding: 20px;
  /* background-color: #f9f9f9; */
  width: 80%;
  margin: auto;
}

.about-us h1 {
  font-size: 2.5rem;
  color: #ab6f31;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 40px;
  line-height: 1.6;
  text-align: justify;
}

.about-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.section {
  /* background-color: #000; */
  color: #ab6f31;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  width: calc(33.333% - 40px);
  min-width: 280px;
  box-shadow: #ab6f31 0px 5px 15px;
}

.section h2 {
  font-size: 1.8rem;
  color: #ab6f31;
  margin-bottom: 15px;
}

.section p {
  font-size: 1rem;
  color: #000;
  text-align: justify;
}

@media (max-width: 768px) {
  .about-sections {
    flex-direction: column;
  }

  .section {
    width: 100%;
    margin: 10px 0;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .about-us h1 {
    font-size: 1.8rem;
    color: #ab6f31;
    margin-bottom: 20px;
  }

  .about-us p {
    font-size: 0.9rem;
    text-align: justify;
  }
}

.contact-us {
  text-align: center;
  padding: 20px;
  /* background-color: #f9f9f9; */
  width: 60%;
  margin: auto;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.contact-us h1 {
  font-size: 2.5rem;
  color: #ab6f31;
  margin-bottom: 20px;
}

.contact-us p {
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 40px;
  line-height: 1.6;
  text-align: justify;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.contact-form label {
  font-size: 1.2rem;
  color: #ab6f31;
}

.contact-form input,
.contact-form textarea {
  width: 80%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.contact-form button {
  padding: 15px 25px;
  background-color: #000;
  color: #ab6f31;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  
}

.contact-form button:hover {
  background-color: #ab6f31;
  
}

@media (max-width: 768px) {
  .contact-form input,
  .contact-form textarea {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .contact-us {
    width: 80%;
    margin: auto;
  }

  .contact-us h1 {
    font-size: 1.8rem;
  }

  .contact-us p {
    font-size: 0.7rem;
  }
}

/* Footer start */

.footer-main-box {
  width: 100%;
  /* display: flex; */
  /* gap: 10px; */
  margin: auto;
  /* justify-content: space-around; */
  /* align-items: center; */
  padding: 30px;
  text-decoration: none;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* background-color: #12bed3; */
  box-shadow: 0px -0.5px 0px 0px #ab6f31;
  background-color: #000;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.css-iv7vbz-MuiGrid-root {
  margin: auto !important;
  text-align: center !important;
  justify-content: center !important;
}

.footer-box1{
  margin: auto;
  align-items: center;
  justify-content: center;

}

.footer-box1 img {
  width: 250px;
  /* height: 100px; */
  filter: drop-shadow(5px 5px 5px #ab6f31);
}

.footer-box2 h2 {
  text-align: center;
  font-size: 22px;
}

.footer-box3 h2 {
  text-align: center;
  font-size: 22px;
}

.footer-box2 p {
  color: #f0f0e3;
}

.footer-box3 p {
  color: #f0f0e3;
}

/* Footer end */

.css-8je8zh-MuiTouchRipple-root {
  /* background: #000 !important; */
  border: 2px solid #ab6f31 !important;
  color: #ab6f31 !important;
}

.css-17slstj-MuiSvgIcon-root {
  z-index: 9999999;
}

.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  background-color: #000 !important;
}

.css-i4bv87-MuiSvgIcon-root {
  z-index: 99999999;
}

.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: #000 !important;
  color: #ab6f31;
}

.css-1w5lpmw-MuiSpeedDialIcon-root {
  background: none !important;
}

.css-13srr63-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
  background-color: #000 !important;
}

.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
  background-color: #000 !important;
}

@media (hover: none) {
  .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
      background-color: #000 !important;
  }
}

.css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab:hover {
  background-color: #000 !important;
}

.css-1j4tx5p-MuiSvgIcon-root {
  background-color: #000 !important;
}

.css-13srr63-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
  background-color: #000 !important;
}

.css-1e2cad8-MuiButtonBase-root-MuiFab-root-MuiSpeedDialAction-fab {
  background-color: #000 !important;
}

.css-14lw37v-MuiButtonBase-root-MuiButton-root {
  background-color: #000 !important;
}

.css-bshv44-MuiButtonBase-root-MuiListItem-root {
  margin-bottom: 2px !important;
}


.css-vkc4v8-MuiTypography-root {
  color: #000 !important;
}

.css-10hburv-MuiTypography-root {
  color: #000 !important;
}

.css-18brw8f-MuiTypography-root {
  color: #ab6f31 !important;
}

.park-and-sell {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.how-it-works, .why-choose-us, .get-started {
  margin-top: 30px;
}

.get-started p {
  text-align: center;
  font-weight: bold;
  color: #000;
}

strong {
  color: #ab6f31;
}

.how-it-works li{
  color: #000;
  font-family: 'Cinzel';
  font-family: 'Cinzel';
  font-style: normal;
  text-align: justify;
}

.why-choose-us li{
  color: #000;
  font-family: 'Cinzel';
  font-family: 'Cinzel';
  font-style: normal;
  text-align: justify;
}

/* faqs */

.faq {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.faq-section {
  margin-bottom: 40px;
}


.faq-item {
  background-color: #f9f9f9;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item:hover {
  background-color: #f1f1f1;
}

.faq-question {
  font-weight: bold;
  color: #333;
}

.faq-answer {
  margin-top: 10px;
  display: none;
  color: #555;
}

.faq-item.active .faq-answer {
  display: block;
}

.faq-item.active .faq-question {
  color: #ab6f31;
}

@media (max-width: 600px) {
  .faq {
    padding: 10px;
  }

  .faq-item {
    padding: 10px;
  }

  .park-and-sell {
    width: 100%;
  }
}

/* NewsBlogs start */

.news-blogs {
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: 1fr;
  width: 80%;
  margin: auto;
}

.news-blogs__item {
  background: transparent;
  border: 1px solid #ab6f31;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-blogs__title {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.news-blogs__content {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

/* Responsive Layout */

@media (min-width: 320px) {
  
  .news-blogs {
    width: 90%;
    
  }
}

@media (min-width: 768px) {
  .news-blogs {
    grid-template-columns: repeat(2, 1fr);
  }
  .news-blogs {
    
    width: 90%;
    
  }
}

@media (min-width: 1024px) {
  .news-blogs {
    grid-template-columns: repeat(3, 1fr);
  }
}


/* NewsBlogs end */

ol , ul{
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 10px !important;
  unicode-bidi: isolate;
}